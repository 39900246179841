import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../services/api';

const LoginForm = ({ onLoginSuccess }) => {
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos estén llenos
    if (!nombreUsuario || !password) {
      setError('Todos los campos son necesarios');
      return;
    }

    const response = await login(nombreUsuario, password);
    if (response.cod === 200) {
      onLoginSuccess(response.data.token);
      navigate('/terminales'); // Redirigir al perfil de usuario
    } else {
      setError(response.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-8 mt-10 shadow-md rounded">
      <h2 className="text-2xl mb-4">Login</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4">
        <label className="block text-gray-700">Nombre de Usuario</label>
        <input type="text" value={nombreUsuario} onChange={(e) => setNombreUsuario(e.target.value)} className="mt-1 p-2 w-full border rounded" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Contraseña</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="mt-1 p-2 w-full border rounded" />
      </div>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Login</button>
      <div className="mt-4 text-center">
        <span className="text-gray-700">¿No tienes cuenta? </span>
        <Link to="/register" className="text-blue-500">Regístrate aquí</Link>
      </div>
    </form>
  );
};

export default LoginForm;