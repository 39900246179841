import React from 'react';
import { useParams } from 'react-router-dom';
import GraphComponent from '../components/GraphComponent';

const GraphPage = ({ token }) => {
  const { guid } = useParams();  // Obtener el GUID de la URL

  return (
    <div className="flex flex-col ml-64 mt-16 p-8">
      <h1 className="text-2xl font-bold mb-4">Gráfico de Lecturas</h1>
      {token ? (
        <GraphComponent guid={guid} token={token} />
      ) : (
        <p>No estás autenticado. Por favor, inicia sesión.</p>
      )}
    </div>
  );
};

export default GraphPage;