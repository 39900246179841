import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-4">Bienvenido a la aplicación</h1>
        <p className="text-center text-gray-600 mb-6">Por favor, inicia sesión o regístrate.</p>
        <div className="flex justify-around">
          <Link to="/login">
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Iniciar sesión</button>
          </Link>
          <Link to="/register">
            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Registrarse</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;