import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (nombre_usuario, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, { nombre_usuario, password });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const register = async (nombre_usuario, password, email) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, { nombre_usuario, password, email });
    return response.data;
  } catch (error) {
    return error.response?.data || { cod: 500, message: 'Error en la conexión' };
  }
};


export const getUserProfile = async (token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener el perfil del usuario:', error.response || error.message);
    return {
      cod: error.response ? error.response.status : 500,
      message: 'Error de conexión. Inténtalo de nuevo más tarde.',
    };
  }
};