import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import axios from 'axios';

// Configuración del ícono personalizado para el marcador
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapaPage = ({ token }) => {
  const [terminales, setTerminales] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTerminales = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        if (!token) {
          console.log("Token no encontrado. Redirigiendo al login.");
          navigate('/login');
          return;
        }

        const response = await axios.get(`${apiUrl}/lecturas/terminales`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data.cod === 200 && Array.isArray(response.data.data)) {
          setTerminales(response.data.data);
        } else {
          console.error('Error en la respuesta:', response.data.message);
          setTerminales([]);
        }
      } catch (error) {
        console.error('Error llamada:', error.message);
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 403)) {
          console.log("Token inválido o expirado, redirigiendo al login.");
          navigate('/login');  // Redirige al login si hay un problema de autenticación
        } else {
          console.error('Error fetching terminales:', error);
          setTerminales([]);
        }
      }
    };

    fetchTerminales();
  }, [token, navigate]);

  const handleMarkerClick = (guid) => {
    navigate(`/graficos/${guid}`);
  };

  return (
    <div className="flex flex-col ml-64 mt-16 p-8">
      <h1 className="text-2xl font-bold mb-4">Mapa</h1>
      <MapContainer center={[-33.5557, -70.7923]} zoom={13} style={{ height: "400px", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {terminales.map((terminal, index) => (
          <Marker
            key={index}
            position={[-33.5557, -70.7923]} // Reemplaza con terminal.latitud, terminal.longitud si tienes esas coordenadas
            eventHandlers={{
              click: () => handleMarkerClick(terminal.guid),
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup>
              <div>
                <h2>{terminal.guid}</h2>
                <p>Temperatura: {terminal.temperatura} °C</p>
                <p>Humedad: {terminal.humedad} %</p>
                <p>Temperatura del Suelo: {terminal.temperatura_suelo} °C</p>
                <p>Humedad del Suelo: {terminal.humedad_suelo} %</p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapaPage;