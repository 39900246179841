import React from 'react';
import UserProfile from '../components/UserProfile';
import { useNavigate } from 'react-router-dom';

const UserProfilePage = ({ token, handleLogout }) => {
  const navigate = useNavigate();

  const handleLogoutAndRedirect = () => {
    handleLogout();
    navigate('/login');
  };

  if (!token) {
    navigate('/login');
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <UserProfile token={token} handleLogout={handleLogoutAndRedirect} />
    </div>
  );
};

export default UserProfilePage;