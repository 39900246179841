import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { ChartContainer } from './ui/ChartContainer';

const GraphComponent = ({ guid, token }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!guid || !token) {
          console.error('GUID o token no están definidos');
          return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;

        const response = await axios.get(`${apiUrl}/lecturas/getLecturasByGuid/${guid}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const processedData = response.data.data.map(item => ({
          ...item,
          hora: item.hora.split('.')[0].slice(0, 5),
          temperatura: parseFloat(item.temperatura),
          temperatura_suelo: parseFloat(item.temperatura_suelo),
          humedad: parseFloat(item.humedad),
          humedad_suelo: parseFloat(item.humedad_suelo),
        }));

        setData(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [guid, token]);

  const formatTemperature = (value) => `${value} C˚`;
  const formatHumidity = (value) => `${value} %`;

  const customTooltipFormatter = (value, name) => {
    if (name.includes('temperatura')) {
      return [`${value} C˚`, name];
    } else if (name.includes('humedad')) {
      return [`${value} %`, name];
    }
    return [value, name];
  };

  return (
    <div className="chart-wrapper mx-auto flex flex-col items-center gap-6 p-6 w-full">
      <Card className="w-full">
        <CardHeader>
          <CardTitle>Temperatura Ambiental</CardTitle>
        </CardHeader>
        <CardContent>
          <ChartContainer className="h-[300px]"> {/* Ajuste de altura a 300px */}
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hora" />
                <YAxis tickFormatter={formatTemperature} />
                <Tooltip formatter={customTooltipFormatter} />
                <Line type="monotone" dataKey="temperatura" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </CardContent>
      </Card>

      <Card className="w-full">
        <CardHeader>
          <CardTitle>Temperatura del Suelo</CardTitle>
        </CardHeader>
        <CardContent>
          <ChartContainer className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hora" />
                <YAxis tickFormatter={formatTemperature} />
                <Tooltip formatter={customTooltipFormatter} />
                <Line type="monotone" dataKey="temperatura_suelo" stroke="#ff7300" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </CardContent>
      </Card>

      <Card className="w-full">
        <CardHeader>
          <CardTitle>Humedad Ambiental</CardTitle>
        </CardHeader>
        <CardContent>
          <ChartContainer className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hora" />
                <YAxis tickFormatter={formatHumidity} />
                <Tooltip formatter={customTooltipFormatter} />
                <Line type="monotone" dataKey="humedad" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </CardContent>
      </Card>

      <Card className="w-full">
        <CardHeader>
          <CardTitle>Humedad del Suelo</CardTitle>
        </CardHeader>
        <CardContent>
          <ChartContainer className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hora" />
                <YAxis tickFormatter={formatHumidity} />
                <Tooltip formatter={customTooltipFormatter} />
                <Line type="monotone" dataKey="humedad_suelo" stroke="#387908" />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default GraphComponent;