import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../services/api';

const UserProfile = ({ token, handleLogout }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile(token);

        if (response.cod === 200) {
          setUser(response.data);
        } else {
          setError('Sesión expirada. Por favor, inicia sesión de nuevo.');
          handleLogout(); 
          navigate('/login');
        }
      } catch (err) {
        setError('Error de conexión. Inténtalo de nuevo más tarde.');
        navigate('/login');
      }
    };

    fetchUserProfile();
  }, [token, handleLogout, navigate]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!user) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="max-w-md mx-auto bg-white p-8 mt-10 shadow-md rounded">
      <h2 className="text-2xl mb-4">Perfil de Usuario</h2>
      <div className="mb-4">
        <strong>Nombre de Usuario:</strong> {user.nombre_usuario}
      </div>
      <div className="mb-4">
        <strong>Email:</strong> {user.email}
      </div>
    </div>
  );
};

export default UserProfile;