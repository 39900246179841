import React from 'react';
import { Link } from 'react-router-dom';

const Sidenav = () => {
  return (
    <div className="w-32 h-full bg-gray-200 text-gray-900 flex flex-col p-4 fixed top-0 z-10">
      <div className="pt-16 flex flex-col space-y-4"> {/* flex-col asegura que estén en columna y space-y-4 añade un espaciado vertical */}
        <Link to="/terminales" className="hover:bg-gray-300 p-2 rounded text-center">
          Terminales
        </Link>
        <Link to="/mapa" className="hover:bg-gray-300 p-2 rounded text-center">
          Mapa
        </Link>
      </div>
    </div>
  );
};

export default Sidenav;