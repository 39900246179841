import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/api';

const RegisterForm = ({ onRegisterSuccess }) => {
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos estén llenos
    if (!nombreUsuario || !password || !email) {
      setError('Todos los campos son necesarios');
      return;
    }

    try {
      const response = await register(nombreUsuario, password, email);
      if (response.cod === 200) {
        onRegisterSuccess();
        navigate('/registration-success'); // Redirigir a la página de éxito
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError('Error de conexión. Inténtalo de nuevo más tarde.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-8 mt-10 shadow-md rounded">
      <h2 className="text-2xl mb-4">Register</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4">
        <label className="block text-gray-700">Nombre de Usuario</label>
        <input type="text" value={nombreUsuario} onChange={(e) => setNombreUsuario(e.target.value)} className="mt-1 p-2 w-full border rounded" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Contraseña</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="mt-1 p-2 w-full border rounded" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="mt-1 p-2 w-full border rounded" />
      </div>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Register</button>
    </form>
  );
};

export default RegisterForm;