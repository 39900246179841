import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidenav from '../components/Sidenav';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import UserProfilePage from '../pages/UserProfilePage';
import RegistrationSuccessPage from '../pages/RegistrationSuccessPage';
import TerminalesPage from '../pages/TerminalesPage';
import GraphPage from '../pages/GraphPage';
import MapaPage from '../pages/MapaPage'; // Importa la nueva página Mapa

const AppRouter = ({ token, handleLoginSuccess, handleRegisterSuccess, handleLogout }) => {
  return (
    <Router>
      <div className="flex">
        <Sidenav />
        <div className="ml-30 flex-1">
          <Navbar token={token} onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
            <Route path="/register" element={<RegisterPage onRegisterSuccess={handleRegisterSuccess} />} />
            <Route path="/profile" element={token ? <UserProfilePage token={token} /> : <Navigate to="/login" />} />
            <Route path="/registration-success" element={<RegistrationSuccessPage />} />
            <Route path="/terminales" element={token ? <TerminalesPage token={token} /> : <Navigate to="/login" />} />
            <Route path="/graficos/:guid" element={token ? <GraphPage token={token} /> : <Navigate to="/login" />} />
            <Route path="/mapa" element={token ? <MapaPage token={token} /> : <Navigate to="/login" />} /> {/* Asegura que el token se pase a MapaPage */}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default AppRouter;