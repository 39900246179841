import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TerminalCard from '../components/TerminalCard';

const TerminalesPage = ({ token }) => {
  const [terminales, setTerminales] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTerminales = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        if (!token) {
          console.log("Token no encontrado. Redirigiendo al login.");
          navigate('/login');
          return;
        }

        const response = await axios.get(`${apiUrl}/lecturas/terminales`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data.cod === 200 && Array.isArray(response.data.data)) {
          setTerminales(response.data.data);
        } else {
          console.error('Error en la respuesta:', response.data.message);
          setTerminales([]);
        }
      } catch (error) {
        console.error('Error llamada:', error.message);
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 403)) {
          console.log("Token inválido o expirado, redirigiendo al login.");
          navigate('/login');  // Redirige al login si hay un problema de autenticación
        } else {
          console.error('Error fetching terminales:', error);
          setTerminales([]);
        }
      }
    };

    fetchTerminales();
    const intervalId = setInterval(fetchTerminales, 5000);

    return () => clearInterval(intervalId);  // Limpia el intervalo al desmontar el componente
  }, [token, navigate]);

  return (
    <div className="flex flex-col ml-64 mt-16 p-8">
      <h1 className="text-2xl font-bold mb-4">Terminales</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {terminales.map((terminal, index) => (
          <TerminalCard key={index} terminal={terminal} />
        ))}
      </div>
    </div>
  );
};

export default TerminalesPage;