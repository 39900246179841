import React from 'react';
import RegisterForm from '../components/RegisterForm';

const RegisterPage = ({ onRegisterSuccess }) => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <RegisterForm onRegisterSuccess={onRegisterSuccess} />
    </div>
  );
};

export default RegisterPage;