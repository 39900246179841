import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = ({ token, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <nav className="bg-gray-800 p-4 w-full fixed top-0 left-0 z-10"> {/* w-full para que ocupe todo el ancho */}
      <ul className="flex justify-between">
        <div className="flex space-x-4">
          {token ? (
            <>
              <li>
                <Link to="/profile" className="text-white">Profile</Link>
              </li>
              <li>
                <button onClick={handleLogout} className="text-white">Logout</button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login" className="text-white">Login</Link>
              </li>
              <li>
                <Link to="/register" className="text-white">Register</Link>
              </li>
            </>
          )}
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;