// src/components/ui/ChartContainer.js
import React from 'react';

export const ChartContainer = ({ children, className = '' }) => {
  return (
    <div className={`relative w-full h-64 ${className}`}>
      {children}
    </div>
  );
};

export const ChartTooltip = (props) => <div {...props} />;
export const ChartTooltipContent = (props) => <div {...props} />;