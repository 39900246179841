import React from 'react';
import { useNavigate } from 'react-router-dom';

const RegistrationSuccessPage = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="bg-white p-8 mt-10 shadow-md rounded text-center">
        <h2 className="text-2xl mb-4">Usuario registrado correctamente</h2>
        <p className="mb-4">Tu cuenta ha sido creada con éxito.</p>
        <button 
          onClick={handleLoginRedirect} 
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Inicio de sesión
        </button>
      </div>
    </div>
  );
};

export default RegistrationSuccessPage;